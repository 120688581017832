<template>
  <div id="cps">
    <CreditCard v-if="isCC" />
    <InternationalBank v-if="isIBT" />
    <CPS v-else />
  </div>
</template>

<script>
import CPS from '@/components/deposit/Cps/Index.vue';
import CreditCard from '@/components/deposit/Cps/CreditCard.vue';
import InternationalBank from '@/components/deposit/Cps/InternationalBank.vue';
import { creditCardData, internationalBankData } from '@/constants/payment/depositSetting.json';

export default {
  name: 'cpsView',
  components: { CPS, CreditCard, InternationalBank },
  computed: {
    paymentMethod() {
      return this.$store.state.cps.payment.payment_method;
    },
    isCC() {
      return creditCardData.includes(this.paymentMethod);
    },
    isIBT() {
      return internationalBankData.includes(this.paymentMethod);
    },
  }
};
</script>
